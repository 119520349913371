.wrapper {
	max-width: 1280px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 180px;
	margin-bottom: 300px;

	.content {
		position: relative;
		display: flex;
		align-items: center;

		.title {
			font-style: normal;
			font-weight: 700;
			font-size: 33px;
			line-height: 33px;
			position: relative;
			margin-bottom: 50px;
		}
		.icon {
			position: absolute;
			top: -65px;
			right: -160px;
		}
	}

	.text {
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: center;
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 21px;
		width: 843px;
		height: auto;
		margin-bottom: 70px;
		color: rgba($color: #000000, $alpha: 0.7);

		.first {
			padding-left: 30px;
		}

		.second,
		.third {
			text-align: center;
		}
	}

	.footer {
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 21px;
		text-align: center;
		color: rgba($color: #000000, $alpha: 0.7);
	}
}

@media screen and (max-width: 450px) {
	.wrapper {
		padding: 80px 16px 120px;
		max-width: 1280px;
		margin: 0 auto;
		display: flex;
		align-items: start;
		justify-content: start;
		flex-direction: column;
		height: auto;

		.content {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;

			.title {
				font-size: 28px;
				white-space: pre-line;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
			}
			.icon {
				position: absolute;
				top: -110px;
				left: 0px;
				width: 69px;
				height: 66px;
			}
		}

		.text {
			display: flex;
			flex-direction: column;
			gap: 20px;
			align-items: start;
			font-size: 12px;
			font-family: Roboto Mono;
			font-style: normal;
			font-weight: 100;
			line-height: normal;
			width: 359px;
			margin-bottom: 0px;

			.first {
				padding-left: 0px;
			}

			.second,
			.third {
				text-align: left;
			}
		}

		.footer {
			font-size: 12px;
			font-weight: 200;
			line-height: normal;
			text-align: left;
			width: 140px;
		}
	}
}
