.brand {
	display: flex;
	flex-direction: column;
	position: relative;
	top: 10px;
	padding-bottom: 5px;
	width: 100%;

	flex: 1;
	flex-basis: 323px;

	.image,
	.fav_image {
		width: 100%;
		height: 100%;
	}

	.icon {
		position: absolute;
		right: 0;
		top: 0;
		fill: transparent;
		stroke: #000;
		transition: 0.3s all ease;
		padding: 10px;

		&.favorite {
			fill: #000;
			transition: 0.5s all ease;
		}
	}

	.quality {
		margin-top: 5px;
		font-style: normal;
		font-weight: 300;
		font-size: 12px;
		color: #888383;
		text-transform: lowercase;
	}

	.titleContainer {
		display: flex;
		gap: 10px;

		.brandName {
			font-weight: 400;
			font-size: 16px;
			color: #000000;
			margin: 0;
			text-transform: lowercase;
		}

		.productName {
			font-weight: 300;
		}
	}

	.priceContainer {
		display: flex;
		gap: 20px;
	}

	.price {
		display: flex;
		align-items: center;
		gap: 6px;
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		color: #000000;

		&.striked {
			text-decoration: line-through;
			text-decoration-thickness: 0.5px;
		}
	}

	.salePrice {
		color: #BE2E2E;
	}

	.button {
		padding: 20px;
		border: none;
		outline: none;
		background: none;
		position: absolute;
		top: 0px;
		right: 0px;
		height: 24px;
		width: 24px;
		display: flex;
		align-items: center;
		justify-content: center;

		&::before {
			content: "";
			position: absolute;
			height: 1px;
			width: 12px;
			background: rgba($color: #000000, $alpha: 0.6);
			top: 20;
			left: 20;
			transform: rotate(45deg);
		}

		&::after {
			content: "";
			position: absolute;
			height: 1px;
			width: 12px;
			background: rgba($color: #000000, $alpha: 0.6);
			top: 20;
			left: 20;
			transform: rotate(-45deg);
		}
	}
}

@media screen and (max-width: 550px) {
	.brand {
		display: flex;
		flex-direction: column;
		position: relative;
		top: 0px;
		padding-bottom: 0px;

		&.odd {
			.quality,
			.titleContainer,
			.price {
				padding-left: 5px;
			}
		}

		.image {
			width: 100%;
			height: auto;
		}

		.fav_image {
			width: 100%;
			height: 100%;
		}

		.quality {
			font-style: normal;
			font-size: 7px;
			font-weight: 300;
			color: #888383;
			padding-left: 0px;
			margin-top: 0px;
		}

		.titleContainer {
			display: flex;

			.brandName {
				font-weight: 400;
				font-size: 11px;
				margin: 0;
				height: 12px;
			}

			.productName {
				font-weight: 300;
				font-size: 11px;

				flex: 1;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}


		.priceContainer {
			display: flex;
			gap: 5px;
		}

		.price {
			font-size: 11px;
			font-weight: 300;
			color: #000000;
			padding-left: 0px;

			&.striked {
				text-decoration-thickness: 0.3px;
			}
		}

		.salePrice {
			color: #BE2E2E;
		}
	}
}
