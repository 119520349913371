.wrapper {
    width: 406px;
    height: 503px;

    font-size: 11px;
    
    .content {
        height: 50%;
        padding: 35px;
        padding-top: 120px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        font-weight: 100;

        .consent {
            font-size: 10px;
        }

        hr {
            background-color: rgba(0, 0, 0, 0.4);
            height: 0.2px;
            border: none;
        }
    }

    .buttonContainer {
        padding: 0 35px;
        margin-top: 40px;

        display: flex;
        justify-content: flex-end;

        .button {
			outline: none;
			border: none;
			background: #181717;
			width: 183px;
			height: 29px;
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 700;
			color: white;
			font-family: "RoadRadio";
		}
    }
}

@media screen and (max-width: 550px) {
	.wrapper {
		width: 354px;
        height: 248px;

        .content {
            height: 40%;
            padding-top: 45px;
        }

        .buttonContainer {
            margin-top: 0;
        }
	}
}