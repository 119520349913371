.wrapper {
	width: 100%;
	min-height: 88vh;

	margin: 0 auto;
	display: flex;
	flex-direction: column;
	position: relative;

	margin-top: 70px;
	margin-bottom: 160px;

	.title {
		text-align: center;
		margin-bottom: 81px;
		font-style: normal;
		font-weight: 700;
		font-size: 33px;
		line-height: 33px;
		color: #000000;
	}

	.productList {
		display: grid;
		grid-template-columns: repeat(3, minmax(100px, 1fr));

		justify-content: center;

		padding: 0;

		&.one {
			grid-template-columns: repeat(auto-fit, 33%);
		}

		// @media (min-width: 500px) {
		// 	&:not(:has(> :nth-child(2))) {
		// 		grid-template-columns: repeat(auto-fit, 30%);
		// 	}
		// }

		// @media (max-width: 500px) {
		// 	&:not(:has(> :nth-child(2))) {
		// 		grid-template-columns: repeat(1, 197px);
		// 	}
		// }
	}
}

@media screen and (max-width: 500px) {
	.wrapper {
		min-height: 85vh;
		width: 100%;
		margin-top: 80px;
		padding: 0;

		.title {
			position: relative;
			top: 30px;
			text-align: center;
			margin-bottom: 100px;
			font-size: 22px;
			font-weight: 700;
			color: #000000;
		}

		.productListWrapper {
			margin: 0;
		}

		.productList {
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			align-items: center;
			justify-content: center;
			padding-left: 0;
			padding-bottom: 90px;

			&.one {
				grid-template-columns: repeat(1, 197px);
			}
		}
	}
}

.loader {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1000;
	background: rgba(255, 255, 255, 1);
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}