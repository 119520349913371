.label {
	position: relative;

	.radio {
		position: relative;
		z-index: 100;
	}

	.option {
		display: flex;
		gap: 7px;
		align-items: center;
		font-size: 12px;
		font-weight: 300;
		margin-bottom: 8px;
		margin-top: -2px;

		& span {
			flex-shrink: 2;
		}

		.strike {
			position:absolute;
			height: 0.5px;
			background:black;
			top: 7px;
			left: -3.5px;
		}
	}

	.span {
		display: none;
		opacity: 0;
		height: 0;
		font-weight: 300;
	}

	&.show {
		.span {
			display: flex;
			padding-bottom: 10px;
			font-size: 10px;
			height: auto;
			width: 98%;
			opacity: 1;

			&.disabled {
				text-decoration: line-through;
			}
		}
	}
}

input[type="radio"].radio {
	display: none;
}

input[type="radio"].radio + .option:before {
	content: "";
	display: inline-block;
	margin-top: 2px;
	width: 7px;
	height: 7px;
	border: 1px solid #000;
	border-radius: 50%;
	transition: 0.3s all ease;
}

input[type="radio"].radio:checked + .option:before {
	background-color: #000;
	transition: 0.3s all ease;
}

@media screen and (max-width: 500px) {
	.label {
		&.show {
			.span {
				width: 88%;
			}
		}
	}
}
