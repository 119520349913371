@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.wrapper {
	position: absolute;
	top: 50%;
	left: 49%;
	width: 40px !important;
	animation: spin 0.35s linear infinite;
}

.circle {
	stroke-width: 18px;
}

@media screen and (max-width: 500px) {
	.wrapper {
		top: 48%;
		left: 45%;
	}
}
