.background {
    width: 100vw;
    height: 100dvh;

    background-color: rgba(0, 0, 0, 0.4);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    display: flex;

    .dialog {
        background-color: white;
    }
}