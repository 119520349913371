.wrapper {
	border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
	display: flex;
	flex-direction: column;
	width: 70%;
	margin: 0 auto;
	position: relative;

	.title {
		display: flex;
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 21px;
		position: relative;
		border: none;
		background: none;
		padding: 0;
		margin: 0;
		color: #000000;
	}

	.cross,
	.plus {
		border: none;
		outline: none;
		position: absolute;
		top: -2px;
		right: 0px;
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: transform 0.3s;
		background: none;
	}

	.plus:before,
	.plus:after {
		content: "";
		position: absolute;
		width: 12px;
		height: 1px;
		background: rgba($color: #000000, $alpha: 0.5);
		transition: 0.3s all ease;
	}

	.plus:before {
		transform: rotate(90deg);
	}

	.cross:before,
	.cross:after {
		content: "";
		position: absolute;
		width: 12px;
		height: 1px;
		background: rgba($color: #000000, $alpha: 0.5);
		transition: 0.3s all ease;
	}

	.cross:before {
		transform: rotate(45deg);
	}

	.cross:after {
		transform: rotate(-45deg);
	}

	.content {
		font-style: normal;
		visibility: hidden;
		font-weight: 100;
		font-size: 11px;
		line-height: 15px;
		padding: 22px 0;
	}

	&.active {
		padding-bottom: 0px;

		.content {
			visibility: visible;
		}
	}
}

@media screen and (max-width: 1550px) {
	.wrapper {
		width: 80%;
	}
}

@media screen and (max-width: 440px) {
	.wrapper {
		width: 100%;
		display: flex;

		&.active {
			padding-bottom: 10px;

			.content {
				padding: 42px 0;
			}
		}

		.title {
			text-align: left;
			font-size: 14px;
			font-weight: 200;
			width: 90%;
		}

		.content {
			text-align: left;
			margin-left: 0px;
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 42px;
		}

		.cross,
		.plus {
			transition: transform 0.3s;
			right: -43px;
		}
	}
}
