@import "/src/shared/style/fonts.scss";

.wrapper {
	flex: 1;
	margin: 0 auto;
	display: flex;
	flex-direction: column;

	min-height: 88vh;

	margin-top: 70px;
	margin-bottom: 160px;
	width: 100%;

	.title {
		padding: 0 0 81px 70px;
		font-style: normal;
		font-weight: 700;
		font-size: 33px;
		line-height: 33px;
		color: #000000;
	}

	.favorites {
		display: grid;
		grid-template-columns: repeat(3, minmax(100px, 1fr));

		justify-content: center;

		padding: 0;

		&.one {
			grid-template-columns: repeat(auto-fit, 33%);
		}

		// @media (min-width: 450px) {
		// 	&:not(:has(> :nth-child(2))) {
		// 		grid-template-columns: repeat(auto-fit, 30%);
		// 	}
		// }

		// @media (max-width: 450px) {
		// 	&:not(:has(> :nth-child(2))) {
		// 		grid-template-columns: repeat(1, 197px);
		// 	}
		// }

		.brand {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		&:nth-child(odd) {
			.quality {
				padding-left: 0;
			}
		}

		.button {
			margin-top: 12px;
			color: #000;
			font-family: "RoadRadio";
			text-transform: uppercase;
			font-size: 1.1em;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			width: 98%;
			padding: 8px;
			border-radius: 7px;
			outline: none;
			border: 1px solid rgba($color: #000000, $alpha: 0.1);
			background: #fff;
			transition: 0.3s all ease;

			&.added {
				transition: 0.3s all ease;
			}
		}
	}
}

@media screen and (max-width: 450px) {
	.wrapper {
		margin-top: 40px;
		min-height: 95vh;
		width: 100%;

		.favorites {
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			align-items: center;
			justify-content: center;
			margin: 0 auto;
			padding-left: 0;
			padding-top: 114px;
			padding-bottom: 90px;
			row-gap: 20px;

			&.one {
				grid-template-columns: repeat(1, 197px);
			}
		}

		.title {
			padding: 0 16px;
			font-style: normal;
			font-weight: 700;
			font-size: 28px;
			line-height: 33px;
			color: #000000;
		}
	}
}