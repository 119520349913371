.detail {
	display: flex;
	flex-direction: column;
	position: relative;
	margin-bottom: 7px;

	.description {
		display: flex;
		flex-direction: column;
		gap: 10px;

		font-size: 10px;
		font-weight: 300;

		.underline {
			text-decoration: underline;
		}

		.detailed {
			display: flex;

			text-decoration: underline;
			 
			& a {
				color: initial;
			}
		}
	}

	& ul {
		width: max-content;
		display: flex;
		flex-direction: column;
		gap: 4px;
		padding: 0px;
	}

	.list {
		width: max-content;
		display: flex;
		list-style-type: none;
		font-size: 10px;
		gap: 5px;
		font-weight: 300;

		.title {
			font-weight: 400;
		}
	}
}

.close {
	position: absolute;
	right: 0;
	background: none;
	outline: none;
	border: none;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	top: -25px;
	right: -5px;

	&::before {
		content: "";
		position: absolute;
		height: 1px;
		width: 12px;
		background: rgba($color: #000000, $alpha: 1);
		top: 20;
		left: 20;
		transform: rotate(45deg);
	}

	&::after {
		content: "";
		position: absolute;
		height: 1px;
		width: 12px;
		background: rgba($color: #000000, $alpha: 1);
		top: 20;
		left: 20;
		transform: rotate(-45deg);
	}
}
