.wrapper {
	display: flex;
	margin-bottom: 200px;
	justify-content: center;
	align-items: center;
	margin-top: 121px;

	.content {
		display: flex;
		gap: 130px;
		justify-content: center;
		margin-left: 40px;

		.text {
			display: flex;
			flex-direction: column;
			max-width: 27%;
			justify-content: space-between;
			color: rgba($color: #000000, $alpha: 0.7);

			& span {
				font-style: normal;
				font-weight: 100;
				font-size: 16px;
				line-height: 21px;
			}

			.title {
				font-style: normal;
				font-weight: 700;
				font-size: 33px;
				line-height: 33px;
				margin: 0 0 10px 0;
				color: #000000;
			}

			.button {
				margin-top: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #000000;
				font-family: "RoadRadio";
				font-style: normal;
				font-weight: 700;
				font-size: 18px;
				line-height: 18px;
				width: 334px;
				height: 58px;
				background: #ffffff;
				border: 1px solid rgba($color: #000000, $alpha: 0.3);
				border-radius: 7px;
			}
		}
	}
}

@media screen and (max-width: 450px) {
	.wrapper {
		display: flex;
		margin-bottom: 150px;
		justify-content: start;
		align-items: start;
		margin-top: 0px;
		margin-top: 50px;

		.content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			justify-content: start;
			margin-left: 0px;

			& img {
				width: 100%;
				height: auto;
				margin-top: 60px;
			}

			.text {
				padding: 0 16px;
				display: flex;
				flex-direction: column;
				max-width: 354px;
				font-size: 12px;
				font-weight: 200;

				& span {
					font-size: 12px;
					font-weight: 200;
					line-height: normal;
				}

				.title {
					font-size: 28px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
					margin: 0;
				}
			}
		}
	}
}
