.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 200px;
	margin-top: 216px;

	.title {
		margin-bottom: 90px;
	}

	.content {
		display: flex;
		width: 70%;
		justify-content: center;

		.links {
			position: absolute;
			left: 20px;
			display: flex;
			flex-direction: column;
			gap: 40px;
			padding-left: 90px;
		}

		.question {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

@media screen and (max-width: 1550px) {
	.wrapper {
		.content {
			width: 60%;
		}
	}
}

@media screen and (max-width: 450px) {
	.wrapper {
		display: flex;
		flex-direction: column;
		justify-content: start;
		align-items: center;
		margin-top: 0px;
		margin-bottom: 150px;
		padding: 90px 16px;

		.title {
			margin-bottom: 120px;
		}

		.content {
			display: flex;
			width: 100%;
			flex-direction: column;
			gap: 20px;
			position: relative;

			.links {
				position: absolute;
				top: -70px;
				left: 0;
				display: flex;
				flex-direction: row;
				gap: 18px;
				padding-left: 0px;
				font-size: 12px;
				font-weight: 200;
				border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
				width: 100%;

				.underline {
					position: absolute;
					bottom: -1px;
					left: 0;
					height: 1px;
					background: #000;
					transition: all 0.3s ease;
				}
			}

			.question {
				display: flex;
				justify-content: start;
				align-items: start;
			}
		}
	}
}
