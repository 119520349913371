.link {
	font-style: normal;
	font-weight: 200;
	font-size: 16px;
	width: fit-content;
	line-height: 21px;
	transition: 0.3 all ease;
	outline: none;
	background: none;
	border: none;
	padding: 0;
	color: #000000;

	&::after {
		content: "";
		opacity: 0;
		visibility: hidden;
		transition: 0.3s all ease;
	}

	&.active {
		position: relative;

		&::after {
			content: "";
			position: absolute;
			background: #000000;
			width: 98%;
			left: 0;
			height: 1px;
			opacity: 1;
			visibility: visible;
			transition: 0.3s all ease;
			bottom: -1px;
		}

		font-weight: 400;
	}
}

@media screen and (max-width: 450px) {
	.link {
		text-align: center;
		font-size: 12px;
		font-weight: 300;

		&::after {
			display: none;
		}
	}
}
