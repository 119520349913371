.swiper {
	padding: 0;
}

.swiper-button-next {
	cursor: none !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
	font-size: 12px !important;
	color: #000 !important;
	background: #000;
}
