.wrapper {
	position: relative;
	transition: 0.6s all ease;
	display: flex;
	background: white;
	border: none;
	outline: none;
	padding: 0;
	align-items: center;

	.text {
		display: none;
		visibility: hidden;
		font-style: normal;
		font-weight: 400;
		font-size: 10px;
		line-height: 12px;
		width: 140px;
		border-radius: 8px;
		padding: 8px;
		background: rgba($color: #000000, $alpha: 0.9);
		color: white;
		position: absolute;
		z-index: 1;
		bottom: 10px;
		right: 10px;
		opacity: 0;
		transition: opacity 0.6s;
		text-align: left;
	}

	&:hover .text {
		visibility: visible;
		opacity: 1;
		transition: 0.6s all ease;
		display: flex;
	}
}
