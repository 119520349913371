.wrapper {
	max-width: 1280px;
	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	height: 90dvh;

	.content {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.title {
			margin-bottom: 50px;
		}

		.text {
			display: flex;
			flex-direction: column;
			gap: 20px;
			align-items: center;
			font-style: normal;
			font-weight: 100;
			font-size: 16px;
			line-height: 21px;
			width: 853px;
			margin-bottom: 49px;

			.first {
				padding-left: 30px;
			}

			.second {
				text-align: center;
			}
		}

		.footer {
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 21px;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 49px;

			.pleasure {
				font-weight: 400;
				font-size: 16px;
				line-height: 21px;
				text-align: center;
			}

			.links {
				display: flex;
				gap: 100px;

				.link {
					display: flex;
					gap: 10px;
				}

				& a {
					color: #000000;
					border-bottom: 0.01px solid #000000;
				}
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.wrapper {
		max-width: 100%;
		margin: 0;
		padding: 0 16px;
		align-items: inherit;
		height: 100dvh;

		.content {
			position: relative;
			bottom: 20px;
			display: flex;
			align-items: center;
			flex-direction: column;

			.title {
				font-size: 28px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				margin-bottom: 50px;
			}

			.text {
				font-size: 12px;
				font-weight: 300;
				width: 100%;

				.first {
					padding-left: 0px;
				}

				.second {
					text-align: center;
				}
			}

			.footer {
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 21px;
				text-align: center;

				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: flex-start;

				gap: 49px;

				.pleasure {
					font-weight: 400;
					font-size: 12px;
					line-height: 21px;
					text-align: center;
				}

				.links {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					gap: 49px;

					.link {
						display: flex;
						gap: 10px;
					}

					& a {
						color: #000000;
						border-bottom: 0.01px solid #000000;
					}
				}
			}
		}
	}
}
