.sticky_top {
    position: fixed;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0 !important;
}

.side_bar {
    top: 0;
    z-index: 11;
    position: relative;
}