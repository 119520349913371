.wrapper {
	display: flex;
	flex-direction: column;
	margin-bottom: 300px;

	padding: 0 60px 0 70px;

	.error {
		position: absolute;
		bottom: -50px;

		color: #bb1a1a;
	}

	& label {
		font-size: 10px;
		font-style: normal;
		font-weight: 300;
		line-height: normal;
	}

	.pay {
		display: flex;
		flex-direction: column;
		gap: 38px;
		width: 50%;
		position: relative;
		top: -10px;
	}

	.title {
		margin-top: 80px;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		font-size: 33px;

		.phone {
			display: flex;
			flex-direction: column;
			align-items: end;

			& span {
				opacity: 0.8;
				font-size: 12px;
				font-style: normal;
				font-weight: 100;
				line-height: normal;
			}

			& span {
				color: #000;
			}

			& h1 {
				display: flex;
				color: #000;
				gap: 8px;
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
			}
		}
	}

	.all {
		margin-top: 90px;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		position: relative;

		.email {
			& label {
				color: #000;
				font-size: 10px;
				font-style: normal;
				font-weight: 300;
				line-height: normal;
			}

			.error {
				font-size: 12px;
				position: absolute;
				bottom: -20px;
				text-transform: lowercase;
			}
		}

		.email,
		.commentary {
			display: flex;
			flex-direction: column;

			& input {
				border: none;
				outline: none;
				border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
				width: 632px;
				border-radius: 0;

				&::focus {
					outline: none;
				}
			}
		}

		.first,
		.content {
			display: flex;
			flex-wrap: wrap;
			gap: 30px;

			.telephone {
				position: relative;
				& label {
					color: #000;
					font-size: 10px;
					font-style: normal;
					font-weight: 300;
					line-height: normal;
				}

				.error {
					font-size: 12px;
					position: absolute;
					bottom: -20px;
					text-transform: lowercase;
				}
			}

			& section {
				display: flex;
				flex-direction: column;

				& input {
					border: none;
					outline: none;
					border: none;
					width: 300px;
					border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
					border-radius: 0;

					&.flitting {
						width: 632px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1550px) {
	.wrapper {
		.all {
			margin-top: 0;
			
			.email,
			.commentary {
				& input {
					border: none;
					outline: none;
					border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
					width: 540px;
					border-radius: 0;

					&::focus {
						outline: none;
					}
				}
			}

			.first,
			.content {
				& section {
					& input {
						width: 254px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.wrapper {
		padding: 0 16px !important;
		margin-bottom: 70px;

		.error {
			position: absolute;
			color: #bb1a1a;
		}

		& label {
			font-size: 10px;
			font-weight: 300;
		}

		.pay {
			display: flex;
			flex-direction: column;
			width: 100%;
		}

		.title {
			margin-top: 0;
			padding: 50px 0 72px 0;
			font-size: 28px;

			.phone {
				display: none;
			}
		}

		.first {
			flex-direction: column;
		}

		.email,
		.commentary {
			display: flex;
			flex-direction: column;
			width: auto;
			position: relative;

			& label {
				font-size: 10px;
				font-weight: 300;
			}

			& input {
				width: 100% !important;
				font-size: 16px;
				padding: 0;
			}
		}

		.first,
		.content {
			flex-direction: column;
			& section {
				& input {
					width: 100% !important;
					font-size: 16px;
					padding: 0;
				}
			}
		}

		.all {
			padding: 0;
		}
	}
}
