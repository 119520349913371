@import './shared/style/fonts.scss';

* {
  margin: 0;
	font-family: "RobotoMono";
  cursor: none;
}

h1{
  font-family: "RoadRadio";
  text-transform: uppercase;
}

html,
body,
#root,
.app {
	&,
	* {
		cursor: none;
	}
	min-height: 100dvh;
}

.app {
	display: flex;
	flex-direction: column;
}

.underlinedLink {
	color: #000;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	width: fit-content;
}