@import "/src/shared/style/swiper.scss";
@import "/src/shared/style/fonts.scss";

.wrapper {
	display: flex;
	margin-bottom: 300px;
	position: relative;
	max-width: 1320px;
	// min-height: 88vh;
	margin: 0 auto;
	margin-top: 40px;
	margin-bottom: 35px;

	.id {
		margin-top: 10px;
		font-size: 14px;
		font-style: normal;
		font-weight: 200;
		line-height: normal;
		width: 93px;
		height: 22px;
		background: #F7F7F7;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #000;
	}

	.info {
		display: flex;
		flex-direction: column;
		position: absolute;
		bottom: 5%;
		left: calc(100% - 600px + 235px);
		gap: 2px;
		z-index: 1;

		width: 295px;

		.quality {
			font-weight: 300;
			font-size: 12px;
			line-height: 16px;
			color: #888383;
			text-transform: lowercase;
		}

		.name {
			font-weight: 300;
			font-size: 16px;
			line-height: 21px;
			color: #000000;
			text-transform: lowercase;

			& span {
				font-weight: 400;
			}
		}

		.priceContainer {
			display: flex;
			gap: 10px;
			align-items: center;
		}

		.buttons {
			margin-top: 4px;
			display: flex;
			gap: 4px;

			.cart {
				font-family: "RoadRadio";
				border: none;
				outline: none;
				width: 183px;
				height: 29px;
				background: #181717;

				border-radius: 7px;
				text-transform: uppercase;
				font-weight: 500;
				font-style: normal;
				font-size: 13px;
				line-height: 13px;
				color: #ffffff;

				&.notify {
					background: #245934;
				}
			}

			.favorite {
				display: flex;
				align-items: center;
				width: 106px;
				height: 29px;
				background: #ffffff;
				border: 1px solid rgba($color: #000000, $alpha: 0.1);
				color: #000;
				border-radius: 7px;
				justify-content: space-between;
				font-family: "RoadRadio";
				text-transform: uppercase;
				font-weight: 500;
				font-style: normal;
				font-size: 13px;
				line-height: 13px;
				transition: 0.3s all ease;
				gap: 4px;

				&.added {
					transition: 0.3s all ease;

					.icon {
						fill: #000;
						transition: 0.3s all ease;
					}
				}

				.icon {
					transition: 0.3s all ease;
					margin-left: 2px;
					fill: none;
				}
			}
		}

		.details {
			outline: none;
			border: none;
			background: transparent;
			font-weight: 200;
			padding: 0;
			font-size: 12px;
			line-height: 16px;
			border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
			width: fit-content;
			color: #000000;
		}

		.lists {
			margin-top: 5px;
			display: flex;
			flex-direction: column;
			gap: 5px;
			padding: 0;
			position: absolute;
			bottom: -120px;
			opacity: 0;
			visibility: 0;
			transition: 0.3s all ease;

			&.showLists {
				opacity: 1;
				visibility: visible;
				transition: 0.3s all ease;
				padding-left: 10px;
			}

			.list {
				display: flex;
				position: relative;

				&::before {
					content: "";
					position: absolute;
					width: 2px;
					height: 2px;
					border-radius: 20px;
					background: rgba($color: #000000, $alpha: 0.3);
					opacity: 0.8;
					left: -10px;
					top: 4px;
				}

				.title {
					font-weight: 400;
					font-size: 8px;
					line-height: 11px;
					color: #000000;
				}

				& span {
					font-size: 8px;
					line-height: 11px;
					color: #000000;
					margin-left: 4px;
					font-weight: 300;
				}
			}
		}
	}
}

.footer {
	position: relative;
	height: 8vh;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	left: 0;

	margin-bottom: 50px;

	.prev {
		display: flex;
		font-size: 16px;
		font-style: normal;
		font-weight: 300;
		line-height: normal;
		position: relative;
		left: 30px;

		& a {
			color: #000;
			text-transform: lowercase;
			margin-top: -2px;
		}

		.icon {
			transform: rotate(180deg);

			fill: #000 !important;
			margin-right: 10px;
		}
	}

	.next {
		display: flex;
		font-size: 16px;
		font-style: normal;
		font-weight: 300;
		line-height: normal;
		position: absolute;
		right: 30px;

		& a {
			color: #000;
			text-transform: lowercase;
			margin-top: -2px;
		}

		.icon {
			margin-left: 10px;
		}
	}
}

@media screen and (max-width: 550px) {
	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: center;
		position: relative;
		margin-top: 65px;
		margin-bottom: 90px;

		.id {
			margin-top: 42px;
		}

		.info {
			width: 95%;
			padding-right: 0px;
			padding: 9px;
			position: relative;
			padding-top: 0;
			top: 10px;

			left: 0;
			right: 0px;
			gap: 0px;

			.agree {
				display: none;
			}

			.accordions {
				margin-top: 80px;
				padding: 10px;
				padding-top: 8px;
				display: flex;
				flex-direction: column;
				gap: 40px;

				.acc {
					display: flex;

					.description {
						display: flex;
						flex-direction: column;
						gap: 20px;
					}

					.link {
						color: #000;
						border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
						width: fit-content;
					}
				}

				& ul {
					display: flex;
					flex-direction: column;
					gap: 4px;
					padding: 0;
				}

				.list {
					list-style-type: none;
					display: flex;
					gap: 5px;
					width: max-content;

					.title {
						font-weight: 400;
					}
				}

				.banner {
					margin-top: 60px;
					font-size: 12px;
					font-style: normal;
					font-weight: 100;
					line-height: normal;
					background: #F7F7F7;
					padding: 15px 13px 15px 21px;

					.more {						
						display: flex;
						justify-content: space-between;
						align-items: end;
						position: relative;
						margin-top: 15px;

						.link {
							border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
							color: #000;
						}

						.legit {
							position: relative;
							top: 5px;
							right: 5px;
						}
					}
				}
			}

			.buttons {
				margin-top: 8px;
				display: flex;
				flex-direction: row-reverse;
				width: 100%;
				justify-content: space-between;
				margin-top: 24px;

				.favorite,
				.cart {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					font-size: 18px;
					height: 40px;
				}
			}
		}
	}
	.footer {
		display: none;
	}
}

.loader {
	padding-bottom: 1000px;
}

.infos {
	display: flex;
	gap: 2px;
	flex-direction: column;
}

.more {
	width: 100%;

	margin-top: 2px;

	.things {
		position: relative;
		z-index: 100;
		display: flex;
		justify-content: space-between;
		gap: 5px;

	}
}

.all {
	.detail,
	.line {
		background: none;
		outline: none;
		border: none;
		font-style: normal;
		font-size: 12px;
		font-weight: 300;
		line-height: normal;
		color: #000;
	}

	.detail {
		padding: 0;

		&.selected {
			text-decoration: underline;
			text-decoration-thickness: 0.6px;
			font-weight: 400;
		}

		&.unavailable {
			text-decoration: line-through;
			text-decoration-thickness: 0.6px;
		}
	}

	.line {
		padding-left: 2px;
	}

	&:last-child {
		.line {
			display: none;
		}
	}
}

.sizes {
	display: flex;
	gap: 5px;
}

@media screen and (min-width: 1400px) {
	.wrapper {
		margin-bottom: 5px;

		.info {
			left: calc(100% - 700px + 365px);
		}
	}
}

@media screen and (min-width: 1800px) {
	.wrapper {
		margin-bottom: 125px;

		.info {
			left: calc(100% - 900px + 665px);
		}
	}
}