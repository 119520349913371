.wrapper {
	max-width: 1280px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	min-height: 88vh;

	.content {
		position: relative;
		display: flex;
		align-items: center;

		.title {
			font-style: normal;
			font-weight: 700;
			font-size: 33px;
			line-height: 33px;
			position: relative;
			margin-bottom: 50px;
		}
	}

	.text {
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: center;
		font-style: normal;
		font-weight: 100;
		font-size: 16px;
		line-height: 21px;
		width: 703px;
		height: 252px;
		margin-bottom: 50px;

		.first,
		.second,
		.third {
			text-align: center;
		}
	}

	.footer {
		display: flex;
		gap: 140px;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		text-align: center;

		& a {
			color: #000;
			border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
		}
	}
}

@media screen and (max-width: 450px) {
	.wrapper {
		min-height: 95dvh;
		max-width: inherit;
		margin: auto;

		.content {
			.title {
				display: none;
				font-size: 28px;
				font-weight: 700;
			}
		}

		.text {
			font-size: 12px;
			font-weight: 200px;
			width: 359px;
			margin: 0;
			margin-top: 50px;

			.first,
			.second,
			.third {
				text-align: left;
			}
		}

		.footer {
			margin-top: 50px;
			display: flex;
			gap: 100px;
			font-size: 12px;
			font-weight: 300;
			line-height: normal;
		}
	}
}
