@font-face {
	font-family: "RobotoMono";
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url("../fonts/RobotoMono-Thin.ttf") format("truetype");
}

@font-face {
	font-family: "RobotoMono";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url("../fonts/RobotoMono-Thin.ttf") format("truetype");
}

@font-face {
	font-family: "RobotoMono";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("../fonts/RobotoMono-Light.ttf") format("truetype");
}

@font-face {
	font-family: "RobotoMono";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("../fonts/RobotoMono-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "RobotoMono";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url("../fonts/RobotoMono-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "RoadRadio";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("../fonts/RoadRadio-Bold.otf") format("opentype");
}
