.paginationContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	list-style-type: none;
	padding: 0 18px;
	width: 20vw;
	margin: 0 auto;
	margin-top: 130px;

	.paginationItem.hidden {
		visibility: hidden;
	}

	& button {
		border: none;
		outline: none;
		background: none;
	}

	.iconArrowLeft {
		transform: rotate(180deg);
	}

	& li {
		font-size: 12px;
		font-style: normal;
		font-weight: 300;
		line-height: normal;
	}

	& svg {
		width: 7px;
		height: 24px;
	}
}

@media screen and (max-width: 500px) {
	.paginationContainer {
		width: 90vw;

		margin-top: 0px;
		margin-bottom: 90px;
	}
}
