.wrapper {
	max-width: 1280px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-top: 170px;
	margin-bottom: 300px;

	.image {
		position: absolute;
		left: 80px;
		width: 401px;
		height: 600px;
	}

	.right {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 50%;
		gap: 30px;

		margin-left: 39%;

		.contacts {
			display: flex;
			flex-direction: column;
			gap: 20px;

			.title {
				font-style: normal;
				font-weight: 700;
				font-size: 28px;
				line-height: 28px;
				color: #000000;
				margin-bottom: 10px;
			}

			.data {
				font-style: normal;
				font-weight: 200;
				font-size: 15px;
				line-height: 20px;

				& a {
					color: #000000;
					border-bottom: 0.01px solid rgba($color: #000000, $alpha: 0.1);
					color: rgba($color: #000000, $alpha: 0.7);
				}
			}
		}

		.about {
			color: rgba($color: #000000, $alpha: 0.7);

			.title {
				font-weight: 700;
				font-size: 28px;
				line-height: 28px;
				color: #000000;
				margin-bottom: 30px;
			}

			.text {
				font-style: normal;
				font-weight: 200;
				font-size: 16px;
				line-height: 21px;
				max-width: 50%;
			}
		}
	}
}

@media screen and (max-width: 450px) {
	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: start;
		position: relative;
		margin-bottom: 150px;
		margin-top: 50px;

		.image {
			position: relative;
			left: 0px;
			width: 100%;
			margin: 0 auto;
			height: auto;
		}

		.right {
			margin-top: 46px;
			padding: 0 16px;
			display: flex;
			flex-direction: column-reverse;
			width: auto;
			align-items: start;
			justify-content: start;
			margin-left: 0px;
			gap: 40px;

			.contacts {
				display: flex;
				flex-direction: column;
				gap: 20px;

				.title {
					font-style: normal;
					font-weight: 700;
					font-size: 28px;
					line-height: 28px;
					color: #000000;
					margin-bottom: 10px;
				}

				.data {
					font-size: 12px;
					font-weight: 200;
				}
			}

			.about {
				margin-left: 0px;
				max-width: 100%;

				.title {
					font-size: 28px;
					font-weight: 700;
					margin-bottom: 30px;
				}

				.text {
					font-size: 12px;
					font-weight: 200;
				}
			}
		}
	}
}
