.wrapper {
	height: 70vh;

	display: flex;
	align-items: center;
	justify-content: center;

	padding: 0 46px;

	.content {
		width: 95%;
		flex: 1;

		.categories {
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: start;
		}
	}
}

.links {
	text-decoration: none;
}

a {
	text-decoration: none;
}

@media screen and (max-width: 450px) {
	.wrapper {
		flex: 1;
		padding: 0 9px;

		.content {
			justify-content: start;

			.categories {
				justify-content: start;
			}
		}
	}
}
