.price {
    display: flex;
    align-items: center;
    gap: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;

    &.striked {
        text-decoration: line-through;
        text-decoration-thickness: 0.5px;

    }
}

.salePrice {
    color: #BE2E2E;
}

@media screen and (max-width: 550px) {
    .price {
        font-size: 16px;
        font-weight: 300;
        color: #000000;
        padding-left: 0px;
    }

    .salePrice {
        color: #BE2E2E;
    }
}