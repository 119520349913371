@import "/src/shared/style/fonts.scss";

.summary {
	margin-top: 10px;
	position: relative;
	top: -10px;
	width: 40%;

	.title {
		margin-bottom: 24px;
		font-size: 22px;
	}

	.cards {
		display: flex;
		flex-direction: column;
		gap: 20px;

		.card {
			display: flex;
			justify-content: space-between;

			& section {
				display: flex;
			}

			& img {
				width: 50px;
				height: 61px;
				margin-right: 10px;
			}

			.first {
				display: flex;
				flex-direction: column;
				margin-right: 0;

				.packing {
					font-size: 12px;
					font-weight: 400;
				}

				.state {
					font-size: 12px;
					font-weight: 300;
					width: max-content;
					text-transform: lowercase;
				}
			}

			.second {
				display: flex;
				flex-direction: column;
				align-items: end;

				.name {
					font-size: 12px;
					font-weight: 400;
					width: max-content;
					text-transform: lowercase;
				}

				.price {
					display: flex;
					align-items: center;
					font-size: 12px;
					font-weight: 500;
					text-align: right;

					.ruble {
						position: relative;
						top: 0.1px;
					}
				}
			}
		}
	}

	.order {
		margin-top: 21px;
		display: flex;
		flex-direction: column;
		gap: 8px;
		border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
		padding-bottom: 37px;

		.delivery,
		.sale {
			display: flex;
			justify-content: space-between;
			font-size: 12px;
			font-weight: 300;
			align-items: center;

			.ruble {
				opacity: 0.6;
				position: relative;
				top: 1px;
			}

			& span {
				font-weight: 300;
			}
		}

		.sale {
			font-weight: 400;

			& span {
				font-weight: 400;
			}
		}
	}

	.result {
		margin-top: 28px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 12px;
		font-weight: 500;
		margin-bottom: 40px;

		.ruble {
			position: relative;
			top: 1.6px;
		}

		& span {
			font-weight: 500;
		}
	}

	.buttons {
		display: flex;
		flex-direction: column;
		gap: 12px;

		.promocode,
		.pay {
			font-family: "RoadRadio";
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 700;
			width: 100%;
			padding: 8px 0;
			outline: none;
			border: 1px solid rgba($color: #000000, $alpha: 0.1);
			color: #000000;
			background: white;
			border-radius: 7px;
		}

		.activate {
			display: flex;
			justify-content: space-between;
			gap: 3px;

			& input {
				text-align: center;
				font-size: 12px;
				font-style: normal;
				font-weight: 100;
				line-height: normal;
				text-transform: uppercase;
				outline: none;
				border-radius: 7px;
				border: 1px solid rgba($color: #000000, $alpha: 0.1);
				width: 78%;
				height: 32px;
				background: #fff;
				padding: 0;
			}

			.button {
				background: white;
				text-transform: lowercase;
				width: 140px;
				height: 34px;
				border-radius: 7px;
				border: 1px solid rgba($color: #000000, $alpha: 0.1);
				font-size: 12px;
				font-style: normal;
				font-weight: 100;
				line-height: normal;
				color: #000;
			}
		}

		.pay {
			color: white;
			background: #181717;
			border: none;
			outline: none;
		}
	}
}

@media screen and (max-width: 500px) {
	.summary {
		margin-top: 70px;
		width: 100%;

		.buttons {
			.activate {
				& input {
					font-size: 16px;
					width: 244px;
				}
			}
		}

		.title {
			font-size: 22px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			margin-bottom: 24px;
		}

		.cards {
			.card {
				.first {
					margin-right: 0;
				}

				.second {
					.price {
						text-align: right;
						font-weight: 450;
					}
				}
			}
		}
	}
}
