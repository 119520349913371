@import "/src/shared/style/fonts.scss";

.wrapper {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 30px;

		position: relative;
		bottom: 5vh; //because header is 10vh

		.text {
			font-style: normal;
			font-weight: 300;
			font-size: 13px;
			line-height: 21px;
		}

		.button {
			font-family: "RoadRadio";
			color: #000;
			background: #ffffff;
			border: 1px solid rgba($color: #000000, $alpha: 0.1);
			border-radius: 7px;
			padding: 16px 36px;
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			line-height: 14px;
			margin: 0;
		}
	}
}

@media screen and (max-width: 450px) {
	.wrapper {
		height: 30vh;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px 10px;

		.content {
			justify-content: center;
			gap: 28px;

			.text {
				padding-left: 6px;

				font-size: 10px;
				font-style: normal;
				font-weight: 100;
				line-height: normal;
				text-transform: lowercase;
			}
		}
	}
}
