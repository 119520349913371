.wrapper {
	flex: 1;
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 88vh;

	.title {
		margin: 0;
		padding: 40px 60px 0 70px;
		font-style: normal;
		font-weight: 700;
		font-size: 33px;
		line-height: 33px;
		color: #000000;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.continue {
			right: 0;
			color: #000;
			border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
			font-size: 12px;
			font-style: normal;
			font-weight: 300;
			line-height: normal;
			text-transform: lowercase;
		}
	}

	.summary {
		display: flex;
		flex-direction: column;
		width: 40%;
		height: 100%;
		margin-top: -15px;
		position: relative;

		.title {
			font-size: 22px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			padding: 0;
		}

		.content {
			display: flex;
			flex-direction: column;
			gap: 8px;
			margin-top: 36px;
			margin-bottom: 36px;

			.sum,
			.delivery {
				width: 100%;
				display: flex;
				justify-content: space-between;
				font-size: 12px;
				font-weight: 400;

				& span {
					font-weight: 400;
					display: flex;
					align-items: center;

					gap: 4px;

					& svg {
						& path {
							fill: rgba($color: #000000, $alpha: 0.8);
						}
					}
				}
			}

			.delivery {
				& span {
					width: auto;
				}
			}

			.sale {
				width: 100%;
				display: flex;
				justify-content: space-between;
				font-size: 12px;
				font-weight: 500;

				& span {
					font-weight: 400;
					display: flex;
					align-items: center;
					gap: 4px;

					& svg {
						& path {
							fill: rgba($color: #000000, $alpha: 0.8);
						}
					}
				}
			}
		}

		.result {
			border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
			padding-top: 16px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			font-size: 12px;
			font-weight: 500;

			& span {
				font-weight: 500;
				display: flex;
				align-items: center;
				gap: 4px;

				& svg {
					& path {
						fill: rgba($color: #000000, $alpha: 1);
					}
				}
			}
		}

		.button {
			margin: 47px 0 37px 0;
			border-radius: 7px;
			background: #181717;
			outline: none;
			border: none;
			width: 100%;
			height: 35px;
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 700;
			color: white;
			font-family: "RoadRadio";
		}

		.end,
		.pay {
			display: flex;
			flex-direction: column;

			.title {
				margin-bottom: 13px;
				font-size: 22px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
			}

			.label {
				position: relative;

				& span {
					display: flex;
					gap: 7px;
					align-items: center;
					font-size: 12px;
					font-weight: 300;
					margin-bottom: 8px;
					margin-top: -2px;
				}
			}
		}
		.pay {
			margin-top: 20px;
		}
	}

	.error {
		color: #d22;
		margin-top: 20px;
		text-transform: lowercase;
		font-size: 12px;
	}

	.phone {
		display: flex;
		flex-direction: column;
		align-items: end;
		position: absolute;
		bottom: -50px;
		right: 0;
		margin-top: 20px;

		& span {
			opacity: 0.6;
			font-size: 12px;
			font-style: normal;
			font-weight: 100;
			line-height: normal;
		}

		& span {
			color: #000;
		}

		& h1 {
			display: flex;
			color: #000;
			gap: 8px;
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
	}
}

.all {
	display: flex;
	gap: 100px;
	justify-content: space-between;
	padding: 0px 60px 0px 70px;

	margin-top: 100px;
	margin-bottom: 100px;

	.carts {
		display: flex;
		flex-direction: column;
		gap: 30px;
		width: 100%;

		.cart {
			display: flex;
			justify-content: space-between;
		}
	}
}

.label {
	position: relative;
}

@media screen and (max-width: 450px) {
	.wrapper {
		margin-top: 40px;
		margin-bottom: 0;

		min-height: 90vh;

		.title {
			padding: 0 16px;
			font-style: normal;
			font-weight: 700;
			font-size: 28px;
			line-height: 33px;
			color: #000000;
			margin-bottom: 20px;

			.continue {
				display: none;
			}
		}
	}

	.all {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0px 16px;

		.carts {
			margin-top: 94px;
			display: flex;
			flex-direction: column;
			width: 100%;

			.cart {
				display: flex;
				justify-content: space-between;
			}
		}
	}
}

.mobile {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0px 16px;
	padding-bottom: 0px;

	.phone {
		display: flex;
		flex-direction: column;
		align-items: end;
		margin-top: 51px;

		& span {
			opacity: 0.8;
			font-size: 12px;
			font-style: normal;
			font-weight: 100;
			line-height: normal;
		}

		& h1 {
			display: flex;
			color: #000;
			gap: 8px;
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
	}

	.carts {
		margin-top: 24px;
		display: flex;
		flex-direction: column;
		gap: 40px;
		width: 100%;

		.cart {
			display: flex;
			justify-content: space-between;
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		gap: 4px;
		margin-top: 6px;
		margin-bottom: 10px;

		position: -webkit-sticky;
		position: sticky;
		background: white;
		padding: 0 0 20px 0;
		width: 100%;
		top: 0;
		z-index: 10;

		.sum,
		.delivery,
		.sale,
		.result {
			display: flex;
			justify-content: space-between;

			.price {
				display: flex;
				align-items: baseline;
				font-weight: 400;

				& svg {
					width: 12px !important;
					height: 12px !important;
					position: relative;
					top: 1.5px;
	
					& path {
						stroke-width: 10px;
						fill: #000;
					}
				}
			}
		}

		.sum,
		.delivery {
			width: 100%;
			font-size: 12px;
			font-weight: 300;

			& div {
				font-weight: 300;
				display: flex;
				align-items: center;

				& svg {
					& path {
						fill: rgba($color: #000000, $alpha: 0.5);
					}
				}
			}
		}

		.sum {
			& span {
				width: 68px;
				justify-content: space-between;
			}
		}

		.sale {
			width: 100%;
			display: flex;
			justify-content: space-between;
			font-size: 12px;
			font-weight: 300;

			& span {
				font-weight: 300;
				display: flex;
				align-items: center;
				width: 68px;
				justify-content: space-between;
			}
		}
		.line {
			width: 100vw;
			position: absolute;
			bottom: 0;
			left: -16px;
			height: 1px;
			background: rgba($color: #000000, $alpha: 0.1);
			opacity: 1;
			transition: opacity 0.3s ease-out;

			&.out {
				opacity: 0;
			}
		}
	}

	.result {
		padding: 0;
		margin: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		font-size: 12px;
		font-weight: 500;

		.price {
			font-weight: 500 !important;
		}

		& span {
			font-weight: 500;
			display: flex;
			align-items: center;
			width: 68px;
			justify-content: space-between;
		}
	}

	.summary {
		display: flex;
		flex-direction: column;
		margin-top: 60px;
		width: 100%;

		.button {
			margin-top: 30px;
			outline: none;
			border: none;
			border-radius: 7px;
			background: #181717;
			width: 100%;
			height: 35px;
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 700;
			color: white;
			font-family: "RoadRadio";
		}

		.end {
			display: flex;
			width: 100%;
			flex-direction: column;
			margin-bottom: 10px;

			.title {
				margin-bottom: 13px;
				font-size: 22px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
			}

			.label {
				& span {
					display: flex;
					gap: 7px;
					align-items: center;
					font-size: 12px;
					font-weight: 300;
					margin-bottom: 15px;
					color: #000;
				}
			}
			&:last-child {
				margin-top: 20px;
			}
		}

		.phone {
			position: static;
			& span {
				color: #000;
			}
		}
	}
}
