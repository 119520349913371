.cursor {
	position: fixed;
	top: 0;
	left: -30px;
	pointer-events: none;
	z-index: 10000;
	cursor: none;

	&:before,
	&:after {
		content: "";
		position: absolute;

		width: 54px;
		height: 1.4px;
		background: #558320;
	}

	&:before {
		transform: rotate(0deg);
	}

	&:after {
		transform: rotate(90deg);
	}
}

.hover,
.swiperHover {
	position: fixed;
	top: 0;
	left: -30px;
	pointer-events: none;
	z-index: 10000;
	cursor: none;

	&:before,
	&:after {
		position: absolute;

		width: 58px;
		height: 1.4px;
		background: #20acd8;
		cursor: none;
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
}

@media screen and (max-width: 500px) {
	.cursor {
		display: none !important;
	}
}
