.wrapper {
	max-width: 1280px;
	margin: 180px auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	min-height: 88vh;

	.content {
		position: relative;
		display: flex;
		align-items: center;

		.title {
			font-style: normal;
			font-weight: 700;
			font-size: 33px;
			line-height: 33px;
			position: relative;
			margin-bottom: 50px;
		}
	}

	.text {
		display: flex;
		flex-direction: column;
		gap: 20px;
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 21px;
		width: 793px;
		margin-bottom: 50px;
		color: rgba($color: #000000, $alpha: 0.7);

		.footer {
			margin-top: 30px;
			display: flex;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			text-align: left;

			& a {
				color: #000;
				border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
				color: rgba($color: #000000, $alpha: 0.7);
				font-size: 16px;
				font-style: normal;
				font-weight: 300;
				line-height: normal;
			}
		}

		.first {
			padding-left: 30px;
		}

		.second,
		.third {
			text-align: center;
		}
	}
}

@media screen and (max-width: 450px) {
	.wrapper {
		min-height: 85dvh;
		max-width: inherit;
		margin: auto;
		padding: 80px 16px;
		padding-bottom: 20px;

		display: flex;
		align-items: start;
		justify-content: center;

		.content {
			.title {
				font-size: 28px;
				font-weight: 700;
			}
		}

		.text {
			font-size: 12px;
			font-weight: 200;
			width: 359px;

			.footer {
				display: flex;
				gap: 100px;
				font-size: 12px;
				font-style: normal;
				font-weight: 300;
				margin-top: 40px;

				& a {
					font-size: 12px;
				}
			}

			.first {
				padding-left: 0px;
			}

			.second,
			.third {
				text-align: left;
			}
		}
	}
}
