.wrapper {
	border-top: 0.2px solid rgba($color: #000000, $alpha: 0.1);
	padding: 56px 52px;
	transition: 0.5s all ease;

	.content {
		font-style: normal;
		font-weight: 300;
		font-size: 10px;
		line-height: 13px;

		& a {
			color: #000;
		}

		.row {
			display: flex;
			justify-content: space-between;

			.column {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				gap: 10px;
			}

			.last {
				justify-content: space-between;
				align-items: flex-end;

				.logo {
					position: relative;
					bottom: 30px;
				}
			}
		}
	}
}

@media screen and (max-width: 550px) {
	.wrapper {
		border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
		padding-top: 78px;
		padding-bottom: 0px;
		padding-inline: 24px;

		.content {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			gap: 87px;

			.row {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: space-between;
				gap: 43px;

				.column {
					display: flex;
	
					flex-direction: column;
					justify-content: initial;
					gap: 8px;

					.logo {
						position: relative;
						top: 14px;
						left: 5px;
					}

					.links,
					.cooperation {
						font-size: 9px;
						font-weight: 400;
						line-height: normal;
		
						// text-align: end;
		
						& a {
							color: #000;
						}
					}
				}

				.last {
					align-items: flex-end;
				}
			}

			.end {
				margin-bottom: 50px;

				text-align: end;

				.protect {
					font-weight: 400;
					font-size: 8px;
					line-height: normal;
				}
			}
		}
	}
}
