.wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 60px;
	margin-bottom: 200px;
	gap: 200px;

	.content {
		padding: 0 60px;
		display: flex;
		flex-direction: column;
		gap: 61px;
		align-items: center;

		.image {
			display: flex;
			justify-content: center;

			margin: 8% 0;
		}

		.text {
			height: 188px;
			display: flex;
			gap: 200px;

			.column {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			.heading {
				font-family: "RoadRadio";

				& h1:last-child {
					font-size: 25px;
				}

				.social {
					font-family: "RoadRadio";
					text-decoration: underline;
					text-decoration-thickness: 1.5px;
					color: initial;
				}
			}

			.description {
				font-size: 15px;
				font-weight: 100;
			}
		}
	}

	.runningLine {
		width: 100%;
		background-color: black;

		height: auto;
		width: 100%;

		display: flex;
		gap: 10px;
	
		overflow: hidden;
	}

	.runningLine .piece {
		display: flex;
		align-items: center;
		gap: 10px;
	
		animation: run 10s infinite linear;

		h1 {
			color: white;

			text-align: center;
			white-space: nowrap;
			font-weight: 100;
		
			font-size: 33px;
		}

		.button {
			width: 186px;
			height: 26px;

			background: #ffffff;
			border: 1px solid rgba($color: #000000, $alpha: 0.1);
			color: #000;
			border-radius: 7px;
			justify-content: space-between;
			font-family: "RoadRadio";
			text-transform: uppercase;
			font-weight: 500;
			font-style: normal;
			font-size: 20px;
		}
	}
	
	.runningLine:hover .piece {
		animation-play-state: paused;
	}
}

@media screen and (max-width: 450px) {
	.wrapper {
		gap: 100px;
		margin-bottom: 100px;

		.content {
			gap: 80px;
			align-items: initial;
			
			padding: 0 16px;

			.image {
				margin: 50% 0;
			}

			.heading {
				& h1 {
					font-size: 28px;
				}
				
				.social {
					font-family: "RoadRadio";
					text-decoration: underline;
					text-decoration-thickness: 1.5px;

					color: initial;
				}
			}

			.heading:last-of-type {
				& h1 {
					font-size: 25px;
				}
			}

			.description {
				display: flex;
				flex-direction: column;
				gap: 30px;

				font-size: 12px;
				font-weight: 100;
			}
		}

		.runningLine {
			height: 40px;
		}

		.runningLine .piece {
			animation-duration: 5s;
			
			h1 {
				font-size: 20px;
			}
		}
	}
}

@keyframes run {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-100% - 10px));
    }
}