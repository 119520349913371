.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100dvh;
}

.mobileWrapper {
    margin-top: -2.5vh;
}

.content {
	display: flex;
	flex-direction: column;
}
