.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	min-height: 88vh;
}

.title {
	margin-top: 140px;
	margin-bottom: 84px;
}

.content {
	display: flex;
	width: 100%;
	justify-content: center;
}

.links {
	position: absolute;
	left: -20px;
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding-left: 90px;
}

.text {
	display: flex;
	flex-direction: column;
	// justify-content: center;
	gap: 20px;
	font-style: normal;
	font-weight: 200;
	font-size: 16px;
	line-height: 21px;
	max-width: 55%;
	color: rgba($color: #000000, $alpha: 0.7);
}

@media screen and (max-width: 450px) {
	.wrapper {
		padding: 0 16px;
		align-items: start;
		// justify-content: center;
		min-height: 95dvh;
	}

	.title {
		margin-top: 80px;
		margin-bottom: 60px;
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 40px;

		position: relative;
		width: 98%;

		// min-height: 30%;
	}

	.links {
		position: relative;
		left: 0;
		// top: -60px;
		// left: 0;
		display: flex;
		flex-direction: row;
		gap: 18px;
		padding-left: 0px;
		font-size: 12px;
		font-weight: 200;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);

		.underline {
			position: absolute;
			bottom: -1px;
			left: 0;
			height: 1px;
			background: #000;
			transition: all 0.3s ease;
		}
	}

	.text {
		font-size: 12px;
		font-style: normal;
		font-weight: 100;
		line-height: normal;
		max-width: 100%;
	}
}
