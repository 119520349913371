.wrapper {
	display: flex;
	height: 10vh;
	justify-content: space-between;
	align-items: baseline;
	padding-top: 1%;
	padding-inline: 60px;

	.burger {
		display: flex;
		position: relative;
		align-items: center;
		gap: 500px;

		.menu {
			position: relative;
			z-index: 10;
			border: none;
			outline: none;
			width: 40px;
			height: 40px;
			background: transparent;
			display: flex;
			align-items: center;
			justify-content: center;

			&::before {
				content: "";
				position: absolute;
				top: 15px;
				width: 16px;
				height: 2px;
				background: #000;
				left: 10px;
				transition: 0.3s all ease;
			}

			&::after {
				content: "";
				position: absolute;
				top: 22px;
				left: 10px;
				width: 16px;
				height: 2px;
				background: #000;
				transition: 0.3s all ease;
			}

			&.cross {
				transition: 0.3s all ease;

				&::before {
					top: 18px;
					transform: rotate(45deg);
					transition: 0.3s all ease;
				}
				&::after {
					top: 18px;
					transition: 0.3s all ease;
					transform: rotate(-45deg);
				}
			}
		}

		.navbar {
			position: absolute;
			left: 50px;
			width: 490px;
			height: 40px;
			display: flex;
			align-items: center;
			gap: 20px;
		}
	}

	.logo {
		position: relative;
		left: 22px;
	}

	.icons {
		display: flex;
		// align-items: center;
		justify-content: center;
		gap: 10px;

		.icon {
			position: relative;
			top: -2px;
			stroke: #000;
			fill: none !important;
		}

		.carticon {
			stroke: none;
			fill: none !important;
			position: relative;
			top: -4px;
			margin-right: 2px;
		}

		.favorites,
		.cart {
			position: relative;
			z-index: 10;
			font-size: 12px;
		}
	}
}

.mobile {
	height: 5vh;
	padding-inline: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: white;
	padding-bottom: 2.5vh;

	.logo {
		transition: 0.3s all ease;
		position: relative;
		z-index: 1000;

		&.change {
			& path {
				fill: white;
				transition: 0.3s all ease;
			}
		}
	}

	.right {
		display: flex;
		align-items: center;
		gap: 5px;
	}

	.right {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		margin-top: 22px;
		margin-left: 30px;

		.icon {
			position: relative;
			top: -1px;
			stroke: #000;
			fill: none !important;
		}

		.carticon {
			stroke: none;
			fill: none !important;
			position: relative;
			top: -4px;
			margin-right: 2px;
		}

		.favorites,
		.cart {
			position: relative;
			z-index: 10;
			font-size: 12px;
			margin-right: 1px;
		}

		.favorites {
			position: relative;
			top: 0.5px;
		}
	}

	.burger {
		display: flex;
		align-items: center;
		gap: 25px;
		z-index: 100;

		&.open {
			&::after {
				content: "";
				position: fixed;
				background: #000;
				width: 100vw;
				height: 100dvh;
				top: 0;
				left: 0;
			}

			.menu {
				&::before {
					background: #fff;
				}

				&::after {
					background: #fff;
				}
			}
		}

		.menu {
			position: relative;
			z-index: 100;
			border: none;
			outline: none;
			width: 15px;
			height: 40px;
			background: transparent;
			display: flex;
			align-items: center;
			justify-content: center;

			&::before {
				content: "";
				position: absolute;
				top: 13px;
				width: 16px;
				height: 2px;
				background: #000;
				left: 0px;
				transition: 0.3s all ease;
			}

			&::after {
				content: "";
				position: absolute;
				top: 19px;
				left: 0px;
				width: 16px;
				height: 2px;
				background: #000;
				transition: 0.3s all ease;
			}

			&.cross {
				transition: 0.3s all ease;

				&::before {
					top: 18px;
					transform: rotate(45deg);
					transition: 0.3s all ease;
				}

				&::after {
					top: 18px;
					transform: rotate(-45deg);
					transition: 0.3s all ease;
				}
			}
		}

		.navbar {
			position: absolute;
			right: 18px;
			top: 42dvh;
			display: flex;
			flex-direction: column;
			justify-content: start;
			align-items: flex-end;
			gap: 15px;
		}
	}
}
