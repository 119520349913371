.category {
	margin: 0;
	display: flex;
	gap: 0px;
	align-items: center;

	&.hovered {
		.image {
			opacity: 1;
		}

		.slash {
			visibility: hidden;
		}
	}

	&:last-child {
		.slash {
			display: none;
		}
	}

	.title,
	.sale,
	.brand,
	.slash {
		font-weight: 200;
		font-size: 30px;
		line-height: 40px;
		margin: 0;
		position: relative;
		z-index: 1;
		display: flex;
		color: #000;
		text-transform: lowercase;

		&:not(.slash) {
			padding: 2px 20px;
		}
	}

	.title {
		font-weight: 400;
	}

	.image {
		position: fixed;
		top: 250px;
		width: 500px;
		opacity: 0;
		transition: 0.3s all ease;
	}

	.sale {
		color: #bb1a1a;
		font-weight: 400;
	}

	&:hover {
		.image {
			opacity: 1;
			pointer-events: none;
		}
	}

	.slash {
		transition: 0.3s all ease;
		visibility: visible;
		transition: visibility 0.3s ease;

		&.hideSlash {
			opacity: 0;
			transition: 0.3s all ease;
		}
	}
}

@media screen and (max-width: 450px) {
	.category {
		.title,
		.sale,
		.brand,
		.slash {
			font-size: 17px;
			font-weight: 300;
			line-height: normal;
			margin: 0;
			position: relative;
			z-index: 1;
			display: flex;
			color: #000;

			&:not(.slash) {
				padding: 2px 6px;
			}

			&:first-child {
				padding-left: 5px;
			}
		}

		.title {
			font-weight: 400;
		}

		.sale {
			color: #bb1a1a;
			font-weight: 400;
		}
		.image {
			display: none;

			&:hover {
				display: none;
			}
		}
	}
}
