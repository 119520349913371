.wrapper {
	display: flex;
	align-items: center;
	height: auto;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;

	.link {
		font-weight: 200;
		font-size: 12px;
		line-height: 16px;
		margin-top: 0;
		margin-bottom: 4px;
		color: #000000;

		&.active {
			font-weight: 400;
		}

		&.last {
			line-height: 20px;
		}
	}

	&.open {
		opacity: 1;
		visibility: visible;
		height: auto;
	}
}

@media screen and (max-width: 500px) {
	.wrapper {
		flex-direction: column;
		width: 200px;
		gap: 10px;
		position: static;

		.link {
			z-index: 1000;
			color: #fff;
			font-size: 22px;
			font-weight: 400;
			text-align: end;

			&:last-child {
				line-height: 100%;
			}
		}

		.phone {
			display: flex;
			position: absolute;
			gap: 8px;
			bottom: 0;
			align-items: center;
			z-index: 1000;

			& span {
				opacity: 1;
				font-size: 12px;
				font-style: normal;
				font-weight: 100;
				line-height: normal;
				color: #fff;
			}

			.icon {
				fill: white;
			}
		}
	}
}
