.block {
    position: fixed;
    bottom: 5px;
    height: auto;
    width: 100%;
    margin: 0 auto;
    display: flex;

    overflow: hidden;
}

.block p {
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    white-space: nowrap;
    font-weight: 100;

    font-size: 12px;

    animation: run 15s infinite linear;
}

.block p:hover {
    animation-play-state: paused;
}

@keyframes run {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
}
  