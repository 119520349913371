.mySwiper {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 50vw;
	margin: 0 auto;

	.swiper-button-prev {
		cursor: none;
		color: #000;
		&::before {
			content: "";
			position: absolute;
			width: 10px;
			height: 1px;
			transform: rotate(60deg);
			background: #000000;
		}

		&::after {
			content: "";
			position: absolute;
			width: 10px;
			height: 1px;
			transform: rotate(-58deg);
			background: #000000;
			left: 8.5px;
			top: 13px;
		}

		&:hover::before {
			opacity: 1;
		}

		&:focus {
			outline: none;
		}
	}

	.swiper-button-next {
		cursor: none;
		color: #000;

		&::before {
			content: "";
			position: absolute;
			width: 10px;
			height: 1px;
			transform: rotate(-58deg);
			background: #000000;
		}

		&::after {
			content: "";
			position: absolute;
			width: 10px;
			height: 1px;
			transform: rotate(60deg);
			background: #000000;
			left: 8.5px;
			top: 13px;
		}

		&:hover::before {
			opacity: 1;
		}

		&:focus {
			outline: none;
		}
	}

	.image {
		object-fit: cover;
		width: 80%;
		height: auto;
		position: relative;
		display: block;
		margin: auto;
		user-select: none;
	}
}

@media not all and (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) {
		.mySwiper .image {
			width: 563px;
			height: 750px;
		}
	}
}

@media screen and (max-width: 550px) {
	.mySwiper {
		align-items: start;
		justify-content: start;
		top: 0;
		left: 0;
		width: 100%;

		.image {
			width: 100%;
			height: auto;

			position: relative;
			left: 0px;
		}
	}

	.swiper-button-next {
		display: none;
	}

	.swiper-button-prev {
		display: none;
	}

	@media not all and (min-resolution: 0.001dpcm) {
		@supports (-webkit-appearance: none) {
			.mySwiper .image {
				width: 100%;
				height: auto;

				position: relative;
				left: 0px;
			}
		}
	}
}

@media screen and (min-width: 1200px) {
	.mySwiper {
		width: 600px;
	}
}

@media screen and (min-width: 1400px) {
	.mySwiper {
		width: 700px;
	}
}

@media screen and (min-width: 1800px) {
	.mySwiper {
		width: 900px;
	}
}