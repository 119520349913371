.cart {
	width: 90%;
	border-top: 1px solid rgba($color: #000000, $alpha: 0.1);

	.content {
		display: flex;
		padding-top: 26px;
		position: relative;

		.image {
			width: 142px;
			height: 174px;
			margin-right: 26px;
		}

		.info {
			color: #000;
			font-size: 12px;
			margin-right: 125px;
			width: 150px;
			text-transform: lowercase;

			.quality {
				color: #888383;
				font-size: 10px;
				font-weight: 300;
			}

			.id {
				text-transform: uppercase;
				padding-top: 14px;
			}

			.name {
				width: max-content;
				.title {
					font-weight: 300;
				}
			}

			.packing {
				font-weight: 300;
			}

			.total {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 23px;
				padding: 2px 6px;
				background: #f1f3f2;
				width: 40px;
			}
		}

		.price {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			color: #000;
			font-size: 12px;
			margin-right: 96px;
			width: 60px;
			text-align: right;
			gap: 4px;

			.alls {
				display: flex;
				align-items: center;
				font-weight: 500;

				&.striked {
					text-decoration: line-through;
					text-decoration-thickness: 1px;
				}
			}

			.sale {
				color: #BE2E2E;
				font-weight: 400;
			}

			& span {
				color: #888383;
				font-size: 12px;
				font-weight: 300;
			}
		}

		.size {
			display: flex;
			flex-direction: column;
			color: #000;
			font-size: 12px;
			gap: 4px;

			& span {
				color: #888383;
				font-size: 12px;
				font-weight: 300;
			}

			.favorite {
				display: flex;
				align-items: center;
				gap: 8px;
				margin-top: 67px;
				font-size: 12px;
				font-weight: 100;

				& span {
					border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
				}

				.icon {
					fill: white;
					margin: 0;

					&.favorite {
						fill: #000;
						transition: 0.5s all ease;
					}
				}

				.link {
					color: #000;
					border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
				}
			}
		}

		.buttonWrapper {
			flex: 1;

			display: flex;
			justify-content: flex-end;

			.button {
				border: none;
				outline: none;
				background: none;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 16px;
				width: 16px;

				padding: 0;
			
				position: relative;
				top: 0px;
		
				&::before {
					content: "";
					position: absolute;
					height: 1px;
					width: 16px;
					background: rgba($color: #000000, $alpha: 0.8);
					transform: rotate(45deg);
				}
		
				&::after {
					content: "";
					position: absolute;
					height: 1px;
					width: 16px;
					background: rgba($color: #000000, $alpha: 0.8);
					transform: rotate(-45deg);
				}
			}
		}
	}

}

.mobile {
	display: flex;
	flex-direction: column;
	width: 100%;
	border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
	padding-bottom: 25px;
	color: #000;

	.infoWrapper {
		display: flex;

		.image {
			width: 76px;
			height: 93px;
			margin-right: 19px;
		}
	
		.content {
			display: flex;
			flex-direction: column;
	
			.info {
				height: 93px;
	
				display: flex;
				flex-direction: column;
				justify-content: space-between;
	
				font-size: 10px;
				font-weight: 400;
	
				.nameWrapper {
					display: flex;
					flex-direction: column;
					margin-top: -3px;
	
					.quality {
						color: #888383;
						font-size: 8px;
						font-weight: 300;
						text-transform: lowercase;
					}
		
					.name {
						font-weight: 300;
						color: inherit;
						display: flex;
						width: max-content;
						text-transform: lowercase;
						text-decoration: none;
		
						& span {
							font-weight: 400;
						}
					}
		
					.packing {
						font-weight: 300;
					}
				}
	
	
				.id {
					font-weight: 300;
				}
	
				.total {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 83px;
					padding: 2px;
					background: #f1f3f2;
				}
			}
	
			.size {
				display: flex;
				flex-direction: column;
				color: #000;
				font-size: 10px;
	
				width: auto;
	
				& span {
					color: #888383;
					font-size: 10px;
					font-weight: 300;
				}
			}
	
			.favorite {
				width: 100%;
				display: flex;
				align-items: center;
				gap: 8px;
				margin-top: 20px;
				font-size: 10px;
				font-weight: 100;
	
				& span {
					border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
				}
	
				.icon {
					fill: white;
					transition: 0.5s all ease;
					width: 15px !important;
					height: 15px !important;
					margin: 0;
	
					&.favorite {
						fill: #000;
						transition: 0.5s all ease;
					}
				}
			}
		}
	
		.end {
			flex: 1;
			width: fit-content;
			display: flex;
			flex-direction: column;
			align-items: end;
			justify-content: start;
	
			.total {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 58px;
				width: 44px;
				height: 14px;
				background: #f1f3f2;
				font-size: 8px;
				font-weight: 300;
				margin-bottom: 20px;
			}
	
			.price {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
	
				color: #000;
				font-size: 12px;
				margin-right: 0px;
				font-size: 10px;
				font-weight: 300;
				margin-top: -2px;
				gap: 2px;
			}
	
			.button {
				border: none;
				outline: none;
				background: none;
				position: relative;
				height: 24px;
				width: 24px;
				display: flex;
				align-items: center;
				justify-content: center;
				top: -5px;
				right: -5px;
		
				&::before {
					content: "";
					position: absolute;
					height: 1px;
					width: 12px;
					background: rgba($color: #000000, $alpha: 0.6);
					top: 20;
					left: 20;
					transform: rotate(45deg);
				}
		
				&::after {
					content: "";
					position: absolute;
					height: 1px;
					width: 12px;
					background: rgba($color: #000000, $alpha: 0.6);
					top: 20;
					left: 20;
					transform: rotate(-45deg);
				}
			}
		}
	}

	.res {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;

		font-size: 10px;
		font-weight: 400;

		.priceWrapper {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;

			.striked {
				text-decoration: line-through;
				text-decoration-thickness: 0.5px;
			}
		}

		.priceWrapper:not(:last-child) {
			position: relative;
			right: 5px;
		}

		.sale {
			color: #BE2E2E;
		}

		.ruble {
			position: relative;
			left: 5px;
			bottom: 1.5px;
		}
	}
}

@media screen and (max-width: 380px) {
	.mobile {
		.content {
			.info {
				width: 82px;
			}
		}
	}
}
