.wrapper {
    display: flex;
    flex-direction: column;
    gap: 85px;
}

@media screen and (max-width: 450px) {
    .wrapper {
        flex: 1;
        gap: 45px;
    }
}