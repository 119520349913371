.wrapper {
    width: 463px;
    height: 574px;

    display: flex;
    flex-direction: column;

    .header {
        padding: 30px 40px;

        display: flex;
        justify-content: space-between;

        .id {
            font-size: 14px;
            font-style: normal;
            font-weight: 200;
            line-height: normal;

            width: 93px;
            height: 22px;

            background: #f1f3f2;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000;
        }

        .closeButton {
            border: none;
            outline: none;
            background: none;
            height: 24px;
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            padding: 0;

            position: relative;
            left: 5px;
    
            &::before {
                content: "";
                position: absolute;
                height: 1px;
                width: 15px;
                background: rgba($color: #000000, $alpha: 0.6);
                top: 20;
                left: 20;
                transform: rotate(45deg);
            }
    
            &::after {
                content: "";
                position: absolute;
                height: 1px;
                width: 15px;
                background: rgba($color: #000000, $alpha: 0.6);
                top: 20;
                left: 20;
                transform: rotate(-45deg);
            }
        }
    }

    .content {
        padding: 0 40px;
        
        font-size: 12px;
        font-weight: 100;

        .one {
            margin-top: 30px;
            margin-bottom: 80px;
        }

        .two {
            margin-bottom: 95px;
        }

        .inputSection {
            margin-bottom: 30px;
        }

        .acceptanceWrapper {
            margin-bottom: 50px;
        }

        hr {
            background-color: rgba(0, 0, 0, 0.4);
            height: 0.2px;
            border: none;
        }

        .text {
            font-size: 11px;
        }

        .label {
            font-size: 9px;
        }

        & input {
            border: none;
            outline: none;
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
            width: 100%;
            border-radius: 0;

            padding: 0;

            &::focus {
                outline: none;
            }
        }

        & .error {
            margin-top: 5px;
            font-size: 9px;
            text-transform: lowercase;
            color: #bb1a1a;
            opacity: 0;

            &.show {
                opacity: 1;
            }
        }

        .acceptance {
            font-size: 11px;
        }
    }

    .buttonContainer {
        padding: 0 40px 50px 0;

        position: relative;
        top: 0;

        display: flex;
        justify-content: flex-end;

        .button {
			outline: none;
			border: none;
			background: #181717;
            width: 183px;
			height: 29px;
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 700;
			color: white;
			font-family: "RoadRadio";
		}
    }
}

@media screen and (max-width: 550px) {

	.wrapper {
		width: 348px;
        height: 574px;

        .header {
            padding: 30px 20px;
        }

        .content {
            padding: 0 20px;

            .one {
                margin-top: 40px;
                margin-bottom: 60px;
            }
    
            .two {
                margin-bottom: 75px;
            }

            .acceptance {
                // font-size: 11px;
                gap: 10px
            }

            .acceptanceWrapper {
                margin-bottom: 45px;
            }

            .text {
                font-size: 11px;
            }
        }
        
        .buttonContainer {
            padding: 15px 23px 0 0;
        }
	}
}